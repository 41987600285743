import React from 'react';

const styles = {
  quotations: {
    fontSize: 24,
  },
};

const QuoteCard = ({ image, quote, who, location }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '30px',
      }}
    >
      <div
        style={{
          borderRadius: '50%',
          width: '75px',
          height: '75px',
          overflow: 'hidden',
        }}
      >
        <img src={image} style={{ height: '100%' }} />
      </div>
      <div style={{ fontSize: 20 }}>
        <span style={styles.quotations}>"</span>
        {quote}
        <span style={styles.quotations}>"</span>
      </div>
      <span
        style={{
          color: 'gray',
        }}
      >
        {who} ({location})
      </span>
    </div>
  );
};

export default QuoteCard;
