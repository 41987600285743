import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import FuneralCard from '../components/funeral-card';
import QuoteCard from '../components/quote-card';
import headshot1 from '../images/headshot1.png';
import headshot2 from '../images/headshot2.png';
import headshot3 from '../images/headshot3.png';
import PunchlineModal from '../components/punchline-modal';
import { FaFrown, FaArrowRight } from 'react-icons/fa';

const IndexPage = () => {
  const [punchlineModalActive, setPunchlineModalActive] = useState(false);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <title>Free food from Funerals | Sad Eats</title>
        <meta
          name="description"
          content="Find funerals in your area; snag free food!"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div style={{ overflowX: 'hidden' }}>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '0px',
              height: '500px',
              width: '100%',
              color: 'white',
              backgroundImage:
                'url(https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1200,format=auto,quality=50/https://cdn.doordash.com/media/consumer/home/landing/new/hero_landing_us_mobile.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: 'red',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -10,
            }}
          />
          <div
            style={{
              padding: '16px',
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginRight: 'auto',
                alignItems: 'center',
                fontSize: '1rem',
              }}
            >
              <FaFrown
                style={{
                  marginRight: '6px',
                  fontSize: 30,
                }}
              />
              <h1 style={{ margin: 0, padding: 0 }}>SadEats</h1>
            </div>
            <h3
              style={{
                margin: 0,
                fontSize: '3rem',
                marginTop: '200px',
                textShadow: '0px 1px 30px rgba(0,0,0,0.6)',
              }}
            >
              Buffets and more
            </h3>
            <h2
              style={{ margin: 0, textShadow: '0px 1px 30px rgba(0,0,0,0.6)' }}
            >
              Eat for free at funerals near you
            </h2>
            <div
              style={{
                marginTop: '30px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 30,
                border: 'none',
                height: '100%',
                width: '300px',
                backgroundColor: 'white',
                padding: '12px',
                color: 'black',
              }}
              onClick={() => setPunchlineModalActive(true)}
            >
              <div
                style={{
                  color: 'gray',
                }}
              >
                Enter your location
              </div>
              <FaArrowRight
                style={{
                  marginLeft: 'auto',
                  color: 'red',
                  fontSize: 20,
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '80px',
            }}
          >
            <div
              style={{
                padding: '16px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '35px',
                marginBottom: '24px',
              }}
            >
              <h4>Upcoming Funerals</h4>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '100%',
                  flexWrap: 'wrap',
                }}
              >
                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,format=auto,quality=50/https://cdn.doordash.com/media/photos/b84ea1f0-43e6-41cf-8acb-3784b7e01f2d-retina-large-jpeg"
                  where="Lifestyles Church"
                  when="12pm Today"
                  distance="5mi"
                  onClick={() => setPunchlineModalActive(true)}
                />

                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,format=auto,quality=50/https://cdn.doordash.com/media/photos/5bc02a14-1673-4a1e-a5dd-e9f150341a3f-retina-large.jpg"
                  where="New Beginnings"
                  when="5pm Today"
                  distance="12mi"
                  onClick={() => setPunchlineModalActive(true)}
                />
                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,format=auto,quality=50/https://cdn.doordash.com/media/photos/add94a9b-27c5-4c9d-983e-56241d645565-retina-large-jpeg"
                  where="Harmony Baptist"
                  when="1pm Tomorrow"
                  distance="7mi"
                  onClick={() => setPunchlineModalActive(true)}
                />
                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photos/3e7b04c7-55f3-4413-87eb-d06bf1ace5f5-retina-large.jpg"
                  where="Hooters"
                  when="12:30pm Friday"
                  distance="23mi"
                  onClick={() => setPunchlineModalActive(true)}
                />
                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photos/2d8bc0f4-2eea-4bf8-89d4-f02750d30aa5-retina-large-jpeg"
                  where="Deceased's Residence"
                  when="12pm Friday"
                  distance="5mi"
                  onClick={() => setPunchlineModalActive(true)}
                />
                <FuneralCard
                  image="https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photos/6dcb1fc0-25d4-48b8-81cd-87c0cc92fa79-retina-large-jpeg"
                  where="Trinity Southern"
                  when="3pm Saturday"
                  distance="12mi"
                  onClick={() => setPunchlineModalActive(true)}
                />
              </div>
              <button
                type="button"
                style={{
                  border: 'none',
                  background: 'none',
                  color: 'red',
                  cursor: 'pointer',
                }}
                onClick={() => setPunchlineModalActive(true)}
              >
                View More
              </button>
            </div>

            <div
              style={{
                borderBottom: '1px solid lightgrey',
                width: '100%',
                marginBottom: '16px',
              }}
            />

            <div>
              <QuoteCard
                image={headshot1}
                quote="They treated me like family!"
                who="David"
                location="New York City, NY"
              />
              <QuoteCard
                image={headshot3}
                quote="Funerals always have the best foods"
                who="Emma"
                location="Gulfport, MS"
              />
              <QuoteCard
                image={headshot2}
                quote="I loved the environment; dinner and a show!"
                who="Sam"
                location="Seattle, WA"
              />
            </div>
            <div
              style={{
                borderBottom: '1px solid lightgrey',
                width: '100%',
                marginBottom: '16px',
              }}
            />
            <div
              style={{
                height: '75px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '20px',
              }}
            >
              <div>
                This is only a joke made by{' '}
                <a href="https://gage-langdon.com">Gage Langdon</a>
              </div>
            </div>
          </div>
        </div>
        <PunchlineModal
          active={punchlineModalActive}
          onClose={() => setPunchlineModalActive(false)}
        />
      </div>
    </>
  );
};

export default IndexPage;
