import React from 'react';
import { FaGrinTears as LaughIcon } from 'react-icons/fa';

export default ({ active, onClose }) => {
  if (!active) return null;
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0, 0.7)',
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: 'fixed',
          top: '20%',
          width: '100%',
          maxWidth: '400px',
          height: '200px',
          backgroundColor: 'white',
          borderRadius: '7px',
          display: 'flex',
          flexDirection: 'column',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            width: '100%',
            height: '30px',
            backgroundColor: 'red',
            display: 'flex',
            borderRadius: '7px 7px 0 0',
          }}
        >
          <button
            type="button"
            style={{
              // width: '100%',
              padding: '8px',
              marginLeft: 'auto',
              border: 'none',
              backgroundColor: 'red',
              height: '100%',
              borderRadius: '0px 7px 0 0',
            }}
            onClick={onClose}
          >
            <span style={{ color: 'white' }}>X</span>
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '45px',
            fontSize: '1.5rem',
          }}
        >
          Gotcha! This is only a Joke!
        </div>
      </div>
    </div>
  );
};
