import React from 'react';

const FuneralCard = ({ image, where, distance, when, onClick }) => {
  return (
    <div
      style={{
        width: '150px',
        maxWidth: '50%',
        padding: '16px',
        borderRadius: '12px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '16px',
        minHeight: '100px',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <div>
        <img style={{ maxHeight: '100px', borderRadius: '7px' }} src={image} />
      </div>
      <div
        style={{
          marginTop: 'auto',
        }}
      >
        <b>{where}</b>
      </div>
      <div>
        {distance} · {when}
      </div>
    </div>
  );
};

export default FuneralCard;
